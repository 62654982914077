<template>
  <div
    v-if="selfPerformMetricsAction.isNot(States.LOADING)"
    class="space-y-6 p-6 flex flex-col max-h-content w-screen 2xl:max-w-[85%]"
  >
    <div class="element-island h-1/5 flex">
      <div class="flex w-full">
        <KpiSwiper
          :kpi-metrics="kpiMetrics"
          :date="startOfSelectedWeek"
        />
      </div>
    </div>

    <div
      class="element-island flex duration-200 transition-[height] ease-linear"
      style="--animate-duration: 0.2s;"
      :class="[showGraphs ? 'h-2/5' : 'h-0 p-0']"
      @transitionend="graphsHidden"
    >
      <Transition
        v-if="selectedProject"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div
          v-show="showGraphsCanvas"
          class="flex w-full relative"
        >
          <div class="absolute left-0 flex items-start space-x-1">
            <button
              type="button"
              class="btn-icon--small"
              :class="{ 'btn-icon--small--active': showDateSelector }"
              @click="toggleDateSelector"
            >
              <Icon
                v-if="showDateSelector"
                name="close"
                class="h-6 w-6"
              />

              <Icon
                v-else
                name="ellipsis-vertical"
                class="h-6 w-6"
              />
            </button>

            <div
              v-show="showDateSelector"
              ref="dateSelector"
              class="flex space-x-2 items-center element-island border border-gray-300"
            >
              <p class="pl-1">
                Week of
              </p>

              <AppInput
                v-model="selectedDate"
                type="date"
                name="to_date"
                :max="maxSelectableDate"
              />

              <button
                type="button"
                class="btn btn--secondary-blue"
                @click="dateToFetch = selectedDate; showDateSelector = false"
              >
                Apply
              </button>
            </div>
          </div>

          <div class="text-center w-1/2">
            <p>
              Plant Utilisation (week commencing {{ startOfSelectedWeek.toFormat('dd/MMM') }})
            </p>

            <PlantUtilisationGraph
              :selected-date="dateToFetch"
              :project-id="selectedProject.id"
            />
          </div>

          <div class="text-center w-1/2">
            <p>
              Weekly Performance
            </p>

            <WeeklyPerformanceGraph
              :selected-date="dateToFetch"
              :project-id="selectedProject.id"
            />
          </div>
        </div>
      </Transition>
    </div>

    <div
      class="element-island duration-200 transition-[height] ease-linear relative overflow-auto"
      :class="[setFullHeight ? 'h-4/5' : 'h-2/5']"
    >
      <div
        class="fixed z-[25]"
        @click="toggleGraph"
      >
        <Icon
          v-if="setFullHeight"
          name="arrows-pointing-in"
          class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
        />

        <Icon
          v-else
          name="arrows-pointing-out"
          class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
        />
      </div>

      <!-- TODO: 20/04/24 pass in the to date and maybe create another api endpoint -->
      <WbsCodeBreakdown
        v-if="selectedProject"
        :project-id="selectedProject.id"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Sidebar from '@/components/layout/Sidebar.vue';
import KpiSwiper from '@/components/ProjectSelector/KpiSwiper.vue';
import PlantUtilisationGraph from '@/components/ProjectSelector/PlantUtilisationGraph.vue';
import WbsCodeBreakdown from '@/components/ProjectSelector/WbsCodeBreakdown.vue';
import WeeklyPerformanceGraph from '@/components/ProjectSelector/WeeklyPerformanceGraph.vue';
import useScreenSize, { ScreenSize } from '@/composables/useScreenSize';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput, dateForInput } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import { onClickOutside } from '@vueuse/core';
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';

const projectsStore = useProjectsStore();

const selectedProject = computed(() => {
  return projectsStore.project;
});

const showGraphs = ref(true);
const showGraphsCanvas = ref(true);
const setFullHeight = ref(false);

const toggleGraph = () => {
  if(showGraphs.value) {
    showGraphsCanvas.value = false;
    showGraphs.value = false;
    setFullHeight.value = true;
  } else {
    if(setFullHeight.value) {
      showGraphs.value = true;
      setFullHeight.value = false;
    }
  }
};

const graphsHidden = () => {
  if(showGraphs.value) {
    showGraphsCanvas.value = true;
  } else {
    showGraphsCanvas.value = false;
  }
};

const showSidebar = ref(true);
const { screenSize } = useScreenSize();

watch(screenSize, (newValue) => {
  if(newValue >= ScreenSize.XL) {
    showSidebar.value = true;
  } else {
    showSidebar.value = false;
  }
}, { immediate: true });

type metrics = {
  plant_utilisation_week: number;
  plant_utilisation: number;
  labour_hours: number;
  plant_hours: number;
  total_variance: number;
  variance_week: number;
};

const kpiMetrics = ref<metrics | undefined>();
const selfPerformMetricsAction = useStoreApiAction(projectsStore.selfPerformMetrics);
// TODO: 25/04/24 update week for now, will set up quantities to change in future
const selectedDate = ref<string>(dateForDateInput(new Date()));
const dateToFetch = ref<string>(selectedDate.value);

const startOfSelectedWeek = computed(() => {
  return DateTime.fromISO(dateToFetch.value).startOf('week');
});

watch([selectedProject, dateToFetch], (newValue) => {
  if(newValue[0]) {
    selfPerformMetricsAction.request(newValue[0].id, { date: newValue[1] }).then((metrics) => {
      kpiMetrics.value = metrics;
    });
  }
}, { immediate: true });

const showDateSelector = ref(false);
const dateSelector = ref();

const toggleDateSelector = () => {
  if(showDateSelector.value) {
    showDateSelector.value = false;
    selectedDate.value = dateToFetch.value;
  } else {
    showDateSelector.value = true;
  }
};

onClickOutside(dateSelector, (event) => {
  showDateSelector.value = false;
  selectedDate.value = dateToFetch.value;
});

const maxSelectableDate = computed(() => {
  return DateTime.now().endOf('week').toISODate();
});
</script>

<style scoped></style>
