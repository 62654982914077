<template>
  <div
    class="space-y-6 p-6 flex flex-col max-h-content w-screen 2xl:max-w-[85%]"
  >
    <div class="element-island h-full overflow-y-auto">
      <div
        v-if="project.contract_start_date && project.contract_end_date"
        class="h-1/2 w-full"
      >
        <canvas
          ref="lineChart"
        />
      </div>

      <div
        v-else
        class="h-1/2 flex justify-center items-center w-full"
      >
        <div>
          Contract has not been set up.

          <RouterLink
            v-if="userStore.authenticated && project && hasSettingsAccess"
            :to="{
              name: 'ProjectSettings', params: { projectId: project.id }, hash: '#head_contract'
            }"
            class="hidden lg:inline-block underline"
          >
            Click here to set it up.
          </RouterLink>
        </div>
      </div>

      <div class="w-full flex text-sm h-1/2">
        <div class="w-1/2 rounded-md shadow-sm border border-gray-300 relative overflow-y-auto">
          <div class="absolute inset-0 overflow-y-auto">
            <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base">
              Contract Status
            </div>

            <table class="w-full">
              <tbody class="child:border-b border-gray-600 font-semibold">
                <tr class="first-child:pl-4 child:p-2">
                  <td>Start Date</td>

                  <td>{{ project.contract_start_date ? project.contractStartDate.toLocaleDateString() : '' }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    End Date
                  </td>

                  <td>{{ project.contract_end_date ? project.contractEndDate.toLocaleDateString() : '' }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Duration
                  </td>

                  <td>{{ project.contract_start_date && project.contract_end_date ? `${project.contractLengthInWeeks} Weeks` : '' }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Contract Value
                  </td>

                  <td>{{ australianCurrency(totalBudget) }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Approved Variations Value
                  </td>

                  <td>{{ australianCurrency(variationOrdersTotalValue) }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Approved Final Account
                  </td>

                  <td>{{ australianCurrency(totalBudget + variationOrdersTotalValue) }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Payments Certified
                  </td>

                  <td>{{ australianCurrency(claimCertifiedTotal) }}</td>
                </tr>

                <tr>
                  <td
                    colspan="2"
                    class="bg-ccm-blue-100 text-white p-2 font-semibold text-base"
                  >
                    Variations
                  </td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>Variations Identified</td>

                  <td>{{ variationOrders.length }}</td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Value of Variations
                  </td>

                  <td>{{ australianCurrency(variationOrdersTotalValue) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="w-1/2 flex flex-col items-center justify-start">
          <h3 class="text-center font-bold text-2xl">
            Work Breakdown
          </h3>

          <div class="w-full my-auto relative h-52 md-height:h-80">
            <canvas
              ref="pieChart"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, cumulativeSum, enumKeyFromValue, roundDecimals } from '@/helpers';
import Claim, { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import Project from '@/models/Project';
import { useClaimsStore } from '@/store/claims';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { useUserStore } from '@/store/user';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { Chart } from 'chart.js';
import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const lineChart = ref();
const pieChart = ref();

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: 'Forecast',
      data: [],
      fill: false,
      borderColor: 'blue',
      tension: 0.1,
      order: 2,
    },
    {
      label: 'Actual',
      data: [],
      fill: false,
      borderColor: 'orange',
      tension: 0.1,
      order: 1,
    },
    {
      label: 'Baseline',
      data: [],
      fill: false,
      borderColor: 'red',
      tension: 0.1,
      order: 3,
    },
  ],
});

const claimsStore = useClaimsStore();
const tradesStore = useTradesStore();
const variationOrdersStore = useVariationOrdersStore();
const fetchClaimsAction = useStoreApiAction(claimsStore.fetchClaims);
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);
const fetchVariationsAction = useStoreApiAction(variationOrdersStore.fetchVariationOrders);

watch(() => project.value?.id, () => {
  fetchTradesAction.request(project.value?.id).catch((error) => {
    console.log(error);
  });

  fetchClaimsAction.request(project.value?.id).catch((error) => {
    console.log(error);
  });

  fetchVariationsAction.request(project.value?.id).catch((error) => {
    console.log(error);
  });
}, { immediate: true });

const claims = computed(() => {
  return claimsStore.models.where('project_id', project.value?.id).get();
});

const trades = computed(() => {
  return tradesStore.models.where('project_id', project.value?.id).get();
});

const monthlyCertifiedCumulative = computed(() => {
  const claimSumPerMonth = new Map<string, number>();

  claims.value.map((claim) => {
    const key = claim.startDate.toLocaleString({ month: '2-digit', year: 'numeric' });

    if(claimSumPerMonth.has(key)) {
      claimSumPerMonth.set(key, claimSumPerMonth.get(key) + claim.totalCertified);
    } else {
      claimSumPerMonth.set(key, claim.totalCertified);
    }
  });

  const cumulativeSum = ((sum) => (value: number) => sum += value)(0);

  return claimSumPerMonth.values().toArray().map(cumulativeSum);
});

const baseline = computed(() => {
  if(!project.value) {
    return [];
  }

  const monthsInProject = project.value.contractMonths;
  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.value.filter((trade) => {
      return trade.baseline_start_date && trade.baseline_end_date;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.baselineStart <= projectMonth && projectMonth <= trade.baselineEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        monthlyTotals[i] += roundDecimals(
          trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))] ??
            0,
        );
      }
    });
  });

  return monthlyTotals;
});

const baselineCumulative = computed(() => {
  return cumulativeSum(baseline.value);
});

const forecast = computed(() => {
  if(!project.value) {
    return [];
  }

  const monthsInProject = project.value.contractMonths;
  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.value.filter((trade) => {
      return trade.forecast_start_date && trade.forecast_end_date && trade.forecast;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.forecastStart <= projectMonth && projectMonth <= trade.forecastEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        monthlyTotals[i] += roundDecimals(
          trade.forecast[Math.round(Math.abs(trade.forecastStart.diff(projectMonth, ['months']).months))] ??
            0,
        );
      }
    });
  });

  return monthlyTotals;
});

const forecastCumulative = computed(() => {
  return cumulativeSum(forecast.value);
});

let chart: Chart | undefined;
let workDoneChart: Chart<'pie'> | undefined;

const updateChartForecastData = (forecastCumulative: number[]) => {
  chart.data.datasets[0].data = forecastCumulative;

  chart.update();
};

const updateChartActualsData = (certifiedCumulative: number[]) => {
  chart.data.datasets[1].data = certifiedCumulative;

  chart.update();
};

const updateChartBaselineData = (baselineCumulative: number[]) => {
  // console.log(monthlyTotals, cumulativeSum(monthlyTotals));

  // for each month in project

  // if trade baseline start >= month && end <= month
  // sum baseline * budget at month index (diff between start and month)

  // cumulative sum on total sum

  chart.data.datasets[2].data = baselineCumulative;

  chart.update();
};

const updateForecastChartLabels = (project: Project) => {
  const contractLength = project.contractLength;

  const labels = Array.from({ length: contractLength }, (_, i) => {
    const date = DateTime.fromJSDate(project.contractStartDate).startOf('month');

    return date.plus({ months: i }).toFormat('MMM-yyyy');
  });

  chart.data.labels = labels;

  chart.update();
};

watch(lineChart, (newValue) => {
  if(newValue) {
    const ctx = lineChart.value.getContext('2d');

    if(chart !== undefined) {
      chart.destroy();
    }

    chart = new Chart(ctx, {
      type: 'line',
      data: chartData.value,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          title: {
            display: false,
          },
        },

        maintainAspectRatio: false,

        scales: {
          y: {
            ticks: {},
          },
        },
      },
    });

    updateChartActualsData(monthlyCertifiedCumulative.value);

    if(project.value) {
      updateForecastChartLabels(project.value);
    }
  }
});

onMounted(() => {
  if(lineChart.value) {
    const ctx = lineChart.value.getContext('2d');

    chart = new Chart(ctx, {
      type: 'line',
      data: chartData.value,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          title: {
            display: false,
          },
        },

        maintainAspectRatio: false,

        scales: {
          y: {
            ticks: {},
          },
        },
      },
    });

    updateChartActualsData(monthlyCertifiedCumulative.value);

    if(project.value) {
      updateForecastChartLabels(project.value);
    }
  }
});

watch(project, (newValue) => {
  if(newValue && chart) {
    updateForecastChartLabels(newValue);
  }
}, { immediate: true });

watch(monthlyCertifiedCumulative, (newValue) => {
  if(chart) {
    updateChartActualsData(newValue);
  }
}, { immediate: true });

watch(baselineCumulative, (newValue) => {
  if(chart) {
    updateChartBaselineData(newValue);
  }
}, { immediate: true });

watch(forecastCumulative, (newValue) => {
  if(chart) {
    updateChartForecastData(newValue);
  }
}, { immediate: true });

const claimCertifiedTotal = computed(() => {
  return claims.value.reduce((total, claim) => {
    if(claim.totalCertified) {
      total += claim.totalCertified;
    }

    return total;
  }, 0);
});

const totalBudget = computed(() => {
  return trades.value.reduce((total, current) => {
    return total + current.totalBudget;
  }, 0);
});

const remainingBudget = computed(() => {
  return Math.max(totalBudget.value - claimCertifiedTotal.value, 0);
});

watch([claimCertifiedTotal, remainingBudget], (newValue) => {
  if(workDoneChart?.data) {
    workDoneChart.data.datasets[0] = {
      data: newValue,
      backgroundColor: [
        'rgb(0, 0, 255, 0.8)',
        'rgb(255, 0, 0, 0.8)',
        // 'orange',
      ],

      hoverOffset: 4,
    };

    workDoneChart.data.labels = [
      `Work Done ${australianCurrency(newValue[0])}`,
      `Total Work ${australianCurrency(newValue[1])}`,
      // `Variations ${australianCurrency(newValue[0])}`,
    ];

    workDoneChart.update();
  }
});

onMounted(() => {
  if(pieChart.value) {
    const pieChartCtx = pieChart.value.getContext('2d');

    if(workDoneChart !== undefined) {
      workDoneChart.destroy();
    }

    const data = {
      labels: [
        `Work Done ${australianCurrency(claimCertifiedTotal.value)}`,
        `Total Work ${australianCurrency(totalBudget.value)}`,
        // `Variations ${australianCurrency(claimCertifiedTotal.value)}`,
      ],

      datasets: [{
        data: [claimCertifiedTotal.value, remainingBudget.value],
        backgroundColor: [
          'rgb(0, 0, 255, 0.8)',
          'rgb(255, 0, 0, 0.8)',
          // 'orange',
        ],

        hoverOffset: 4,
      }],
    };

    workDoneChart = new Chart(pieChartCtx, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          title: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label(this, tooltipItem) {
                return (tooltipItem.dataset.data[tooltipItem.dataIndex] / totalBudget.value * 100).toFixed(2) + '%';
              },
            },
          },
        },

        maintainAspectRatio: false,
      },
    });
  }
});

watch(pieChart, (newValue) => {
  if(newValue) {
    const pieChartCtx = newValue.getContext('2d');

    if(workDoneChart !== undefined) {
      workDoneChart.destroy();
    }

    const data = {
      labels: [
        `Work Done ${australianCurrency(claimCertifiedTotal.value)}`,
        `Total Work ${australianCurrency(totalBudget.value)}`,
        // `Variations ${australianCurrency(claimCertifiedTotal.value)}`,
      ],

      datasets: [{
        data: [claimCertifiedTotal.value, remainingBudget.value],
        backgroundColor: [
          'rgb(0, 0, 255, 0.8)',
          'rgb(255, 0, 0, 0.8)',
          // 'orange',
        ],

        hoverOffset: 4,
      }],
    };

    workDoneChart = new Chart(pieChartCtx, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          title: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label(this, tooltipItem) {
                return (tooltipItem.dataset.data[tooltipItem.dataIndex] / totalBudget.value * 100).toFixed(2) + '%';
              },
            },
          },
        },

        maintainAspectRatio: false,
      },
    });
  }
});

const variationOrders = computed(() => {
  return variationOrdersStore.models.where('project_id', project.value.id).get();
});

const variationOrdersTotalValue = computed(() => {
  return variationOrders.value.reduce((total, variationOrder) => {
    return total + variationOrder.budget;
  }, 0);
});

const userStore = useUserStore();

const hasSettingsAccess = computed(() => {
  return userStore.hasAccessToRoute('ProjectSettings');
});
</script>

<style scoped></style>
