import Trade from '@/models/Trade';
import TradeService from '@/services/TradeService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useTradesStore = defineStore('trades', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Trade),
  },

  actions: {
    async fetchTrades(projectId: Id) {
      const [data, error] = await TradeService.index(projectId);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async createTrade(projectId: Id, form: ApiRequests['api.v1.trades.store']) {
      const [data, error] = await TradeService.create(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateTrade(id: Id, form: ApiRequests['api.v1.trades.update']) {
      const [data, error] = await TradeService.update(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateSort(id: Id, form: ApiRequests['api.v1.trades.sort']) {
      const [data, error] = await TradeService.updateSort(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteTrade(id: Id) {
      const [data, error] = await TradeService.delete(id);

      if(data) {
        this.models.destroy(data.trade.id);

        this.models.save(data.sort_order);

        return data;
      }

      throw error;
    },

    async generateTrades(projectId: Id) {
      const [data, error] = await TradeService.seed(projectId);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateBaselineForecast(id: Id, form: ApiRequests['api.v1.trades.baseline-forecast']) {
      const [data, error] = await TradeService.updateBaselineForecast(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateForecast(id: Id, form: ApiRequests['api.v1.trades.forecast']) {
      const [data, error] = await TradeService.updateForecast(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
  },
});
