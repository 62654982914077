<template>
  <div class="!pt-0">
    <div class="flex flex-col max-h-screen pt-16 pb-2.5 min-h-screen space-y-2.5">
      <div class="flex items-stretch justify-between mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Cashflow Forecasting
          </h1>
        </div>
      </div>

      <div class="element-island overflow-y-auto flex flex-col min-h-max flex-1">
        <div
          class="w-full duration-200 transition-[height] ease-linear"
          style="--animate-duration: 0.2s;"
          :class="[showGraphs ? 'h-96' : 'h-0']"
          @transitionend="graphsHidden"
        >
          <Transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <div
              v-show="showGraphsCanvas"
              class="flex w-full h-full relative"
            >
              <canvas
                ref="chartCanvas"
              />
            </div>
          </Transition>
        </div>

        <div class="w-full flex relative overflow-y-auto flex-1 rounded-md border border-black">
          <div
            class="sticky z-[25] left-2.5 top-2.5"
            @click="toggleGraph"
          >
            <Icon
              v-if="showGraphs"
              name="arrows-pointing-out"
              class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
            />

            <Icon
              v-else
              name="arrows-pointing-in"
              class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
            />
          </div>

          <div class="flex flex-col absolute inset-0">
            <TradeForecastRow
              v-for="trade in trades"
              :key="trade.id"
              :trade="trade"
              @forecast-updated="updateForecastOverrides(project, trades, trade, $event.newForecasts)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TradeForecastRow from '@/components/HeadContract/CashflowForecasting/TradeForecastRow.vue';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { cumulativeSum, roundDecimals } from '@/helpers';
import Project from '@/models/Project';
import Trade from '@/models/Trade';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { Chart } from 'chart.js';
import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const router = useRouter();

watch(project, (newValue) => {
  if(newValue && !newValue.boq_locked) {
    router.push({ name: 'BillOfQuantities', params: { projectId: newValue.id } });
  }
}, { immediate: true });

const tradesStore = useTradesStore();
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);

watch(() => props.projectId, () => {
  fetchTradesAction.request(props.projectId).catch((error) => {
    console.log(error);
  });
}, { immediate: true });

const trades = computed(() => {
  return tradesStore.models
    .with('trade_items')
    .where('project_id', parseInt(props.projectId))
    .orderBy('sort_order')
    .whereHas('trade_items')
    .get();
});

const chartCanvas = ref();
let forecastChart: Chart | undefined;

const updateForecastChartLabels = (project: Project) => {
  const contractLength = project.contractLength;

  const labels = Array.from({ length: contractLength }, (_, i) => {
    const date = DateTime.fromJSDate(project.contractStartDate).startOf('month');

    return date.plus({ months: i }).toFormat('MMM-yyyy');
  });

  forecastChart.data.labels = labels;

  forecastChart.update();
};

const updateBaselineValues = (project: Project, trades: Trade[]) => {
  const monthsInProject = project.contractMonths;
  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.filter((trade) => {
      return trade.baseline_start_date && trade.baseline_end_date;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.baselineStart <= projectMonth && projectMonth <= trade.baselineEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        monthlyTotals[i] += roundDecimals(
          trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))] ??
            0,
        );
      }
    });
  });

  // console.log(monthlyTotals, cumulativeSum(monthlyTotals));

  forecastChart.data.datasets[0].data = cumulativeSum(monthlyTotals);

  forecastChart.update();
  // for each month in project

  // if trade baseline start >= month && end <= month
  // sum baseline * budget at month index (diff between start and month)

  // cumulative sum on total sum
};

const updateForecastOverrides = (project: Project, trades: Trade[], tradeToOverride: Trade, newForecasts: number[]) => {
  const monthsInProject = project.contractMonths;
  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.filter((trade) => {
      return trade.forecast_start_date && trade.forecast_end_date && trade.forecast;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.forecastStart <= projectMonth && projectMonth <= trade.forecastEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        if(trade.id === tradeToOverride.id) {
          monthlyTotals[i] += roundDecimals(
            newForecasts[Math.round(Math.abs(trade.forecastStart.diff(projectMonth, ['months']).months))] ??
              0,
          );
        } else {
          monthlyTotals[i] += roundDecimals(
            trade.forecast[Math.round(Math.abs(trade.forecastStart.diff(projectMonth, ['months']).months))] ??
              0,
          );
        }
      }
    });
  });

  // console.log(monthlyTotals, cumulativeSum(monthlyTotals));

  forecastChart.data.datasets[1].data = cumulativeSum(monthlyTotals);

  forecastChart.update();
  // for each month in project

  // if trade baseline start >= month && end <= month
  // sum baseline * budget at month index (diff between start and month)

  // cumulative sum on total sum
};

const updateForecastValues = (project: Project, trades: Trade[]) => {
  const monthsInProject = project.contractMonths;
  const monthlyTotals = [];

  monthsInProject.forEach((projectMonth, i) => {
    monthlyTotals[i] = 0;

    trades.filter((trade) => {
      return trade.forecast_start_date && trade.forecast_end_date && trade.forecast;
    }).forEach((trade) => {
      // console.log(
      //   projectMonth.toFormat('yyyy-MM-dd'),
      //   trade.baselineStart <= projectMonth,
      //   projectMonth <= trade.baselineEnd,
      // );

      if(trade.forecastStart <= projectMonth && projectMonth <= trade.forecastEnd) {
        // console.log(
        //   Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months)),
        //   trade.baseline_forecast[Math.round(Math.abs(trade.baselineStart.diff(projectMonth, ['months']).months))],
        //   trade.totalBudget,
        // );

        monthlyTotals[i] += roundDecimals(
          trade.forecast[Math.round(Math.abs(trade.forecastStart.diff(projectMonth, ['months']).months))] ??
            0,
        );
      }
    });
  });

  // console.log(monthlyTotals, cumulativeSum(monthlyTotals));

  forecastChart.data.datasets[1].data = cumulativeSum(monthlyTotals);

  forecastChart.update();
  // for each month in project

  // if trade baseline start >= month && end <= month
  // sum baseline * budget at month index (diff between start and month)

  // cumulative sum on total sum
};

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: 'Baseline',
      data: [],
      fill: false,
      borderColor: 'red',
      tension: 0.1,
    },
    {
      label: 'Forecast',
      data: [],
      fill: false,
      borderColor: 'blue',
      tension: 0.1,
    },
  ],
});

onMounted(() => {
  const ctx = chartCanvas.value.getContext('2d');

  forecastChart = new Chart(ctx, {
    type: 'line',
    data: chartData.value,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },

        title: {
          display: false,
        },
      },

      maintainAspectRatio: false,

      scales: {
        y: {
          ticks: {},
        },
      },
    },
  });

  forecastChart.update();

  if(project.value) {
    updateForecastChartLabels(project.value);
    updateForecastValues(project.value, trades.value);
    updateBaselineValues(project.value, trades.value);
  }
});

watch([trades, project], (newValue) => {
  if(forecastChart && newValue[1]) {
    updateForecastChartLabels(newValue[1]);
    updateForecastValues(newValue[1], newValue[0]);
    updateBaselineValues(newValue[1], newValue[0]);
    updateForecastChartLabels(newValue[1]);
    updateForecastValues(newValue[1], newValue[0]);
  }
}, { immediate: true });

const showGraphs = ref(true);
const showGraphsCanvas = ref(true);
const setFullHeight = ref(false);

const toggleGraph = () => {
  if(showGraphs.value) {
    showGraphsCanvas.value = false;
    showGraphs.value = false;
    setFullHeight.value = true;
  } else {
    if(setFullHeight.value) {
      showGraphs.value = true;
      setFullHeight.value = false;
    }
  }
};

const graphsHidden = () => {
  if(showGraphs.value) {
    showGraphsCanvas.value = true;
  } else {
    showGraphsCanvas.value = false;
  }
};
</script>

<style scoped></style>
