<template>
  <div class="lg:!mx-0 flex">
    <Sidebar
      :class="[showSidebar ? 'translate-x-0' : '-translate-x-[95%]']"
      :open="showSidebar"
      @close="showSidebar = false"
      @open="showSidebar = true"
    />

    <Transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
      mode="out-in"
    >
      <component
        :is="dashboardLayout"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import Sidebar from '@/components/layout/Sidebar.vue';
import HeadContractDashboard from '@/components/ProjectSelector/HeadContractDashboard.vue';
import SelfPerformDashboard from '@/components/ProjectSelector/SelfPerformDashboard.vue';
import useScreenSize, { ScreenSize } from '@/composables/useScreenSize';
import { useProjectsStore } from '@/store/projects';
import { computed, ref, watch } from 'vue';

const showSidebar = ref(true);
const { screenSize } = useScreenSize();
const projectsStore = useProjectsStore();

const selectedProject = computed(() => {
  return projectsStore.project;
});

watch(screenSize, (newValue) => {
  if(newValue >= ScreenSize.XL) {
    showSidebar.value = true;
  } else {
    showSidebar.value = false;
  }
}, { immediate: true });

const dashboardLayout = computed(() => {
  switch (selectedProject.value?.default_dashboard) {
    case 'self_perform':
      return SelfPerformDashboard;

    case 'head_contract':
      return HeadContractDashboard;

    default:
      return SelfPerformDashboard;
  }
});
</script>

<style scoped></style>
